import React from "react"
import styled from "styled-components"

const ClientsBlock = ({ item }) => {
  return (
    <Clients className={`clients-block tpl-block  `}>
      <div className="container lg:px-2 px-4 mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-1">
          {item?.map(({ image, logo, link }, index) => (
            <a
              {...(link?.url ? { href: link.url } : {})}
              className="ratio ratio-4x3 relative"
              key={index}
              target={link?.target}
            >
              <img
                className="image-client absolute"
                src={image?.sourceUrl}
                alt={image?.altText}
                srcSet={image?.srcSet}
              />
              <div className="absolute logo-brand flex items-center justify-center">
                <img
                  className="logo-client "
                  src={logo?.sourceUrl}
                  alt={logo?.altText}
                  srcSet={logo?.srcSet}
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </Clients>
  )
}

export default ClientsBlock

const Clients = styled.section`
  .ratio {
    &:before {
      padding-top: calc(3 / 4 * 100%);
    }
    &:after {
      content: "";
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      background: black;
      opacity: 0.5;
    }
  }
  .image-client {
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    right: 0;
    bottom: 0;
  }
  .logo-brand {
    z-index: 2;
    background: white;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
      width: 50px;
      height: 50px;
    }
    img {
      max-width: 75%;
      position: static;
      width: auto;
      height: auto;
    }
  }
`
