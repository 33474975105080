import React from "react"
import styled from "styled-components"

const BenefitsBlock = ({ items }) => {
  return (
    <BlockThreeColumns className={`feature-benefit tpl-block  `}>
      <div className="container lg:px-2 px-4 mx-auto">
        <div className="list-check grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6">
          {items?.map(({ title, description }, index) => (
            <div className="feature-benefit__item " key={index}>
              <div className="feature-benefit__des">
                <h4 className="border-b-2 border-solid pb-2  divide-blue-300 font-medium">
                  {title}
                </h4>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </BlockThreeColumns>
  )
}

export default BenefitsBlock

const BlockThreeColumns = styled.section``
